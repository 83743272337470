<template>
  <section>
    <content-header :title="$t('schedulesForFrequencyOfSendingExceptions')" />
    <div class="container-fluid">
      <div class="main-page-container">
        <div class="row">
          <div class="col-md-12 text-right">
            <button
              class="btn btn-outline-primary ml-2"
              @click="getFrequencies(1)"
            >
              <i class="far fa-search" /> {{ $t('filter') }}
            </button>
            <base-filled-button
              class="float-right"
              icon-class="fas fa-plus"
              :on-click="() => openFormModal()"
              :text="$t('create')"
            />
          </div>
        </div>
      </div>

      <separator class="my-4" :text="$t('listOfSchedules')" />

      <CustomNotFound v-if="noResultsFound" :text="$t('noResultsFound')" />
      <div class="row">
        <div
          v-for="(item, index) in frequencies.data"
          :key="index"
          class="mt-3 col-12"
        >
          <search-result
            :value="item"
            @on-delete="onDelete(item)"
          />
        </div>
      </div>
      <custom-pagination
        v-if="frequencies.count > 0"
        class="my-4"
        :data="frequencies"
        :page-size="pageSize"
        @pagination-go-page="getFrequencies($event)"
        @pagination-rows-per-page="getFrequencies(pageIndex, $event)"
      />
    </div>
    <custom-modal
      v-model="showFormModal"
      :title="$t('schedulesForFrequencyOfSendingException')"
      size="md"
    >
      <validation-observer v-if="showFormModal" ref="formRef" novalidate>
        <div class="row">
          <div class="col-md-12">
            <validation-provider v-slot="{ errors }" rules="required">
              <b-form-group :label="`${$t('exceptionSubmissionSchedule')}:`">
                <base-datetime-picker
                  class="theme-orange"
                  icon-class="clock"
                  is-readonly="true"
                  type="time"
                  :error="errors[0]"
                  :error-msg="$t('invalidField')"
                  :value="executionTime"
                  @change="executionTime = $event"
                />
              </b-form-group>
            </validation-provider>
          </div>
          <div class="col-md-12 text-right mt-3">
            <base-filled-button
              bg-color="#707070"
              class="mx-0 float-left"
              icon-class="fas fa-ban"
              :on-click="() => (showFormModal = false)"
              :text="$t('cancel')"
            />

            <button
              class="btn btn-outline-primary"
              variant="outline-primary"
              @click="onSubmit()"
            >
              <i class="far fa-save" /> {{ $t('save') }}
            </button>
          </div>
        </div>
      </validation-observer>
    </custom-modal>
  </section>
</template>
<script>
import contentHeader from '@/components/Header';
import CustomNotFound from '@/components/basics/alerts/CustomNotFound';
import Separator from '@/components/Separator';
import SearchResult from './components/SearchResult.vue';
import CustomPagination from '@/components/CustomPagination.vue';
import CustomModal from '@/components/basics/modal/CustomModal';
import {
  getShippingFrequencies as _getShippingFrequencies,
  createShippingFrequency as _createShippingFrequency,
  deleteShippingFrequency as _deleteShippingFrequency,
} from '@/services/ShippingFrequencyService';
import moment from 'moment';

export default {
  name: 'ShippingFrequenciesSearch',
  components: {
    contentHeader,
    CustomNotFound,
    Separator,
    SearchResult,
    CustomPagination,
    CustomModal,
  },
  data() {
    return {
      frequencies: {
        data: [],
        totalItemCount: 0,
        count: 0,
        pageCount: 0,
        currentPage: 1,
      },
      executionTime: null,
      noResultsFound: false,
      pageSize: 10,
      pageIndex: 1,
      showFormModal: false,
    };
  },
  methods: {
    openFormModal() {
      this.executionTime = null;
      this.showFormModal = true;
    },
    async getFrequencies(pageIndex = null, pageSize = null) {
      this.pageIndex = pageIndex || this.pageIndex;
      this.pageSize = pageSize || this.pageSize;

      const payload = {
        PageIndex: this.pageIndex,
        PageSize: this.pageSize,
      };

      await _getShippingFrequencies(payload).then(({ data }) => {
        this.frequencies = data;
        this.noResultsFound = !this.frequencies.count;
      });
    },
    async onSubmit() {
      if (!(await this.$refs.formRef.validate())) return;
      _createShippingFrequency(
        moment(this.executionTime)
          .set({ seconds: 0 })
          .format('LTS'),
      )
        .then(() => {
          this.ShowSuccessSaveToast();
          this.showFormModal = false;
          this.getFrequencies();
        })
        .catch((error) => {
          this.ShowErrorToast(error.response.data.message);
        });
    },
    onDelete(item) {
      _deleteShippingFrequency(item.id).then(() => {
        this.ShowSuccessToast(this.$t('successfullyDeleted'));
        this.getFrequencies();
      });
    },
  },
};
</script>
