<template>
  <section>
    <content-header
      :title="$t('loginAdMaintenance')"
    />
    <div class="container-fluid">
      <div class="main-page-container">
        <div class="row">
          <sub-header :title="$t('filterLoginAds')" />
        </div>
        <validation-observer ref="loginAdFormRef" novalidate>
          <filter-manager
            ref="filterComponent"
            v-model="selectedFilters"
            class="mb-4"
            :display-advanced="false"
            :filters="filtersConfig"
            :search-text="$t('filter')"
            @search="search(1)"
          />
        </validation-observer>
      </div>

      <separator class="my-4" :text="$t('listOfLoginAds')" />
      <div class="row mt-4 mb-3">
        <div class="col-sm-12">
          <base-filled-button
            class="float-right"
            icon-class="fas fa-plus"
            :on-click="openCreateModal"
            :text="$t('create')"
          />
        </div>
      </div>
      <CustomNotFound v-if="noResultsFound" :text="$t('noResultsFound')" />
      <div class="row">
        <div
          v-for="(loginAd, index) in loginAds.data"
          :key="index"
          class="mt-3 col-12"
        >
          <search-result
            :value="loginAd"
            @on-view="OnView"
            @on-edit="openEditModal(loginAd)"
            @on-delete="openDeleteModal(loginAd)"
          />
        </div>
      </div>
      <custom-pagination
        v-if="loginAds.count > 0"
        id="pgPager"
        class="my-4"
        :data="loginAds"
        :page-size="pageSize"
        @pagination-go-page="search($event)"
        @pagination-rows-per-page="search(pageIndex, $event)"
      />
    </div>
    <custom-modal
      v-model="showLoginAdFormModal"
      size="md"
      :title="!editModel ? $t('createLoginAd') : $t('editLoginAd')"
    >
      <login-ad-form
        v-if="showLoginAdFormModal"
        v-model="editModel"
        @close="showLoginAdFormModal = false"
        @submitted="search()"
      />
    </custom-modal>
    <DeleteModal
      v-model="showDeleteModal"
      error-message="requiredField"
      :item="editModel"
      :display-label="false"
      name="keyword"
      @on-confirm="onDelete()"
    />
    <BigLoader v-if="loading" />
  </section>
</template>

<script>
import Swal from 'sweetalert2';
import CustomModal from '@/components/basics/modal/CustomModal';
import CustomPagination from '@/components/CustomPagination';
import CustomNotFound from '@/components/basics/alerts/CustomNotFound';
import SearchResult from './components/SearchResult';
import FilterManager from '@/components/FilterManager';
import BigLoader from '@/components/BigLoader';
import contentHeader from '@/components/Header';
import SubHeader from '@/components/SubHeader';
import Separator from '@/components/Separator';
import LoginAdForm from './components/LoginAdForm';
import DeleteModal from '@/components/DeleteModal';
import { 
  getLoginAds as _getLoginAds,
  deleteLoginAd as _deleteLoginAd
  } from '@/services/AdService';

export default {
  name: 'LoginAds',
  components: {
    CustomModal,
    CustomPagination,
    CustomNotFound,
    SearchResult,
    FilterManager,
    contentHeader,
    SubHeader,
    Separator,
    BigLoader,
    DeleteModal,
    LoginAdForm,
  },
  data() {
    return {
      selectedFilters: {
      },
      filters: {
      },
      loginAds: {
        data: [],
        totalItemCount: 0,
        count: 0,
        pageCount: 0,
        currentPage: 1,
      },
      pageSize: 10,
      pageIndex: 1,
      noResultsFound: false,

      showLoginAdFormModal: false,
      showDeleteModal: false,
      loading: false,

      editModel: null,
    };
  },
  async mounted() { 
  },
  computed: {
    filtersConfig() {
      return [ ];
    },
  },
  methods: {
    openCreateModal() {
      this.showLoginAdFormModal = true;
      this.editModel = null;
    },
    openDeleteModal(data) {
      this.showDeleteModal = true;
      this.editModel = { ...data };
    },
    openEditModal(data) {
      this.showLoginAdFormModal = true;
      this.editModel = { ...data };
    },
    onDelete() {
      _deleteLoginAd(this.editModel.id)
        .then(async () => {
          this.ShowSuccessToast(this.$t('successfullyDeleted'));
          this.showDeleteModal = false;
          await this.search();
          this.editModel = null;
        })
        .catch((error) => this.ShowErrorToast(error.response.data.message));
    },
    OnView(data) {
      Swal.fire({
        title: this.$t('viewAd'),
        text: data.keyword,
        imageUrl: data.mediaFile.publicUrl,
        imageAlt: data.alternateText,
      })
    },
    async search(pageIndex = null, pageSize = null) {
      this.pageSize = pageSize || this.pageSize;
      this.pageIndex = pageIndex || this.pageIndex;

      const payload = {
        pageIndex: this.pageIndex,
        pageSize: this.pageSize,
      };

      await _getLoginAds(payload)
        .then(({ data }) => {
          this.loginAds = data;
          this.noResultsFound = !this.loginAds.count;
        })
        .catch((error) => this.ShowErrorToast(error.response.data.message));
    },
  },
};
</script>
<style lang="scss" scoped>
</style>
