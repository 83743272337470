<template>
  <section>
    <content-header
      v-if="showMaintenance"
      :title="$t('salesAvailableSpaces')"
    />
    <div v-if="showMaintenance" class="container-fluid">
      <div class="main-page-container">
        <div class="row">
          <sub-header :title="$t('filterSpaces')" />
        </div>
        <filter-manager
          ref="filterComponent"
          v-model="selectedFilters"
          class="mb-4"
          :display-advanced="false"
          :filters="filtersConfig"
          :search-text="$t('filter')"
          @search="search(1)"
        />
      </div>

      <separator class="my-4" :text="$t('listOfAvailableSpaces')" />
      <CustomNotFound v-if="noResultsFound" :text="$t('noResultsFound')" />
      <div class="row" v-if="this.spacesAvailable.count > 0">
        <div class="mt-3 col-12 col-lg-4">
          <space-indicator
            :amount="indicators.offices"
            :color="'orange'"
            :icon="'building'"
            :title="'offices'"
          />
        </div>
        <div class="mt-3 col-12 col-lg-4">
          <space-indicator
            :amount="indicators.officesWithSpacesAvailable"
            :color="'blue'"
            :icon="'search'"
            :title="'officeWithAvailableSpaces'"
          />
        </div>
        <div class="mt-3 col-12 col-lg-4">
          <space-indicator
            :amount="indicators.officesWithSpacesAvailablePaying"
            :color="'green'"
            :icon="'funnel-dollar'"
            :title="'officesWithAvailableSpacesPaying'"
          />
        </div>
      </div>
      <div class="row">
        <div
          v-for="(ad, index) in spacesAvailable.data"
          :key="index"
          class="mt-3 col-12"
        >
          <available-search-result :value="ad" @on-delete="openDeleteModal(station)" />
        </div>
      </div>
      <custom-pagination
        v-if="spacesAvailable.count > 0"
        id="pgPager"
        class="my-4"
        :data="spacesAvailable"
        :page-size="pageSize"
        @pagination-go-page="search($event)"
        @pagination-rows-per-page="search(pageIndex, $event)"
      />
    </div>
    <BigLoader v-if="loading" />
    <AlertMessage
      v-if="!showMaintenance && !loading"
      :message="$t('selectOfficeForTheMaintenance')"
      style="margin-top: 150px"
    />
  </section>
</template>

<script>
import { mapGetters } from 'vuex';
import CustomPagination from '@/components/CustomPagination';
import CustomNotFound from '@/components/basics/alerts/CustomNotFound';
import AvailableSearchResult from './components/AvailableSearchResult';
import FilterManager from '@/components/FilterManager';
import BigLoader from '@/components/BigLoader';
import contentHeader from '@/components/Header';
import SubHeader from '@/components/SubHeader';
import IdentityPermission from '@/constants/IdentityPermission';
import Separator from '@/components/Separator';
import { modulesName } from '@/store';
import { getCompanies as _getCompanies } from '@/services/CompanyService';
import { getLocationList as _getLocationList } from '@/services/LocationService';
import { getMunicipalities as _getMunicipalities } from '@/services/MunicipalityService';
import { getSaleSpacesAvailable as _getSaleSpacesAvailable } from '@/services/AdService';
import AlertMessage from '@/components/basics/alerts/AlertMessage';
import SpaceIndicator from '@/components/spaces/SpaceIndicator.vue';

export default {
  name: 'SearchSpace',
  components: {
    CustomPagination,
    CustomNotFound,
    AvailableSearchResult,
    FilterManager,
    contentHeader,
    SubHeader,
    Separator,
    BigLoader,
    AlertMessage,
    SpaceIndicator,
  },
  data() {
    return {
      filters: {
        locations: [],
        companies: [],
        cities: [],
      },
      selectedFilters: {
        location: [],
        company: [],
        city: [],
      },
      spacesAvailable: {
        data: [],
        totalItemCount: 0,
        count: 0,
        pageCount: 0,
        currentPage: 1,
      },
      indicators: {
        offices: 0,
        officesWithSpacesAvailable: 0,
        officesWithSpacesAvailablePaying: 0,
      },
      pageSize: 10,
      pageIndex: 1,
      noResultsFound: false,
      showDeleteModal: false,
      showMaintenance: false,
      loading: false,
    };
  },
  mounted() {
    if (!this.isMultiOffice) {
      this.getCompanies();
      this.getCities();
      this.showMaintenance = true;
    } else if (this.isMultiOffice && this.currentActiveLocation) {
      this.showMaintenance = true;
    }
  },
  computed: {
    ...mapGetters(modulesName.userModuleName, ['currentActiveLocation']),
    filtersConfig() {
      if (!this.isMultiOffice) {
        return [
          {
            rules: '',
            name: 'company',
            label: this.$t('company'),
            component: 'FilterMultiSelect',
            options: this.filters.companies,
            placeholder: `-- ${this.$t('all')} --`,
            fieldtext: 'text',
            fieldvalue: 'value',
            clearable: true,
            class: 'col-sm-12 col-md-4 col-lg-3',
            error: this.$t('mustselectcompany'),
            initialValue: this.selectedFilters.company,
            onChanged: this.onCompanyChanged,
          },
          {
            rules: '',
            name: 'location',
            label: this.$t('office'),
            component: 'FilterMultiSelect',
            options: this.filters.locations,
            placeholder: `-- ${this.$t('all')} --`,
            fieldtext: 'name',
            fieldvalue: 'id',
            clearable: true,
            class: 'col-sm-12 col-md-4 col-lg-3',
            initialValue: this.selectedFilters.location,
            onChanged: this.onLocationChanged,
          },
          {
            rules: '',
            name: 'city',
            label: this.$t('town'),
            component: 'FilterMultiSelect',
            options: this.filters.cities,
            placeholder: `-- ${this.$t('all')} --`,
            fieldtext: 'name',
            fieldvalue: 'id',
            clearable: true,
            class: 'col-sm-12 col-md-4 col-lg-3',
            initialValue: this.selectedFilters.city,
            onChanged: this.onCityChanged,
          },
        ];
      }

      return [
        {
          rules: '',
          name: 'city',
          label: this.$t('town'),
          component: 'FilterMultiSelect',
          options: this.filters.cities,
          placeholder: `-- ${this.$t('all')} --`,
          fieldtext: 'name',
          fieldvalue: 'id',
          clearable: true,
          class: 'col-sm-12 col-md-4 col-lg-3',
          initialValue: this.selectedFilters.city,
          onChanged: this.onCityChanged,
        },
      ];
    },
    isMultiOffice() {
      return this.userHasPermissions(IdentityPermission.multi.office);
    },
  },
  watch: {
    currentActiveLocation() {
      this.showMaintenance = false;
      this.loading = true;
      this.noResultsFound = false;
      setTimeout(() => {
        this.selectedFilters = {
          location: [],
          company: [],
          keyboard: [],
        };
        this.spacesAvailable = {
          data: [],
          totalItemCount: 0,
          count: 0,
          pageCount: 0,
          currentPage: 1,
        };
        this.loading = false;
        this.showMaintenance = true;
      }, 1200);
    },
  },
  methods: {
    async getCompanies() {
      await _getCompanies()
        .then((response) => {
          this.filters.companies = response.data || [];
        })
        .catch((error) => this.ShowErrorToast(error.response.data.message));
    },
    async getCities() {
      await _getMunicipalities({ stateCode: 'PR' })
        .then((response) => {
          this.filters.cities = response.data;
        })
        .catch((error) => this.ShowErrorToast(error.response.data.message));
    },
    async getLocations(companyId) {
      await _getLocationList(companyId)
        .then((response) => {
          this.filters.locations = response.data;
        })
        .catch((error) => this.ShowErrorToast(error.response.data.message));
    },
    onCompanyChanged(value) {
      this.selectedFilters.location = [];
      this.selectedFilters.company = [];
      this.filters.locations = [];
      if (value) {
        this.selectedFilters.company = [value];
        this.getLocations(this.selectedFilters.company[0].value);
      }
    },
    onLocationChanged(value) {
      if (!value) return (this.selectedFilters.location = []);

      this.selectedFilters.location = [value];
    },
    onCityChanged(value) {
      if (!value) return (this.selectedFilters.city = []);

      this.selectedFilters.city = [value];
    },
    async search(pageIndex = null, pageSize = null) {
      this.pageSize = pageSize || this.pageSize;
      this.pageIndex = pageIndex || this.pageIndex;

      const payload = {
        CompanyId:
          this.selectedFilters.company.length > 0
            ? this.selectedFilters.company[0].value
            : null,
        LocationId: this.isMultiOffice
          ? Number(this.currentActiveLocation.locationConfigurationId)
          : this.selectedFilters.location.length > 0
          ? this.selectedFilters.location[0].id
          : null,
        CityId:
          this.selectedFilters.city.length > 0
            ? this.selectedFilters.city[0].id
            : null,
        PageIndex: this.pageIndex,
        PageSize: this.pageSize,
      };

      await _getSaleSpacesAvailable(payload)
        .then(({ data }) => {
          this.spacesAvailable = data.data;
          this.noResultsFound = !this.spacesAvailable.count;
          this.indicators = data.indicators;
        })
        .catch((error) => this.ShowErrorToast(error.response.data.message));
    },
  },
};
</script>
