<template>
  <section>
    <content-header :title="$t('sponsorAdMaintenance')" />
    <div class="container-fluid">
      <div class="main-page-container">
        <div class="row">
          <sub-header :title="$t('filterSponsorAds')" />
        </div>
        <validation-observer ref="sponsorFormRef" novalidate>
          <filter-manager
            ref="filterComponent"
            v-model="selectedFilters"
            class="mb-4"
            :display-advanced="false"
            :filters="filtersConfig"
            :search-text="$t('filter')"
            @search="search(1)"
          />
        </validation-observer>
      </div>

      <separator class="my-4" :text="$t('listOfSponsorAds')" />
      <div class="row mt-4 mb-3">
        <div class="col-sm-12">
          <base-filled-button
            class="float-right"
            icon-class="fas fa-plus"
            :on-click="() => openCreateModal()"
            :text="$t('create')"
          />
        </div>
      </div>
      <CustomNotFound v-if="noResultsFound" :text="$t('noResultsFound')" />
      <div class="row">
        <div
          v-for="(sponsorAd, index) in sponsorAds.data"
          :key="index"
          class="mt-3 col-12"
        >
          <search-result
            :value="sponsorAd"
            @on-delete="openDeleteModal(sponsorAd)"
            @on-download="downloadAd(sponsorAd)"
            @on-edit="openEditModal(sponsorAd)"
          />
        </div>
      </div>
      <custom-pagination
        v-if="sponsorAds.count > 0"
        id="pgPager"
        class="my-4"
        :data="sponsorAds"
        :page-size="pageSize"
        @pagination-go-page="search($event)"
        @pagination-rows-per-page="search(pageIndex, $event)"
      />
    </div>

    <BigLoader v-if="loading" />
    <custom-modal
      v-model="showSponsorAdFormModal"
      size="md"
      :title="!editModel ? $t('createSponsorAd') : $t('editSponsorAd')"
    >
      <SponsorAdForm
        v-if="showSponsorAdFormModal == true"
        v-model="editModel"
        @close="showSponsorAdFormModal = false"
        @submitted="search"
      />
    </custom-modal>
    <DeleteModal
      v-model="showDeleteModal"
      error-message="nameIsNotCorrect"
      :item="editModel"
      @on-confirm="onDelete()"
    />
  </section>
</template>

<script>
import CustomPagination from '@/components/CustomPagination';
import CustomNotFound from '@/components/basics/alerts/CustomNotFound';
import SearchResult from './components/SearchResult';
import FilterManager from '@/components/FilterManager';
import BigLoader from '@/components/BigLoader';
import contentHeader from '@/components/Header';
import SubHeader from '@/components/SubHeader';
import Separator from '@/components/Separator';
import SponsorAdForm from './components/SponsorAdForm';
import CustomModal from '@/components/basics/modal/CustomModal';
import DeleteModal from '@/components/DeleteModal';
import {
  getSponsorAds as _getSponsorAds,
  deleteSponsorAd as _deleteSponsorAd,
  getSponsorsList as _getSponsorsList,
  getSponsorAdCategoriesList as _getSponsorAdCategoriesList,
} from '@/services/SponsorService';

export default {
  name: 'RegisteredServiceTypeGroups',
  components: {
    CustomPagination,
    CustomNotFound,
    SearchResult,
    FilterManager,
    contentHeader,
    SubHeader,
    Separator,
    BigLoader,
    SponsorAdForm,
    CustomModal,
    DeleteModal,
  },
  data() {
    return {
      selectedFilters: {
        sponsorId: null,
        sponsorAdCategoryId: null,
      },
      filters: {
        sponsors: [],
        sponsorAdCategories: [],
      },
      sponsorAds: {
        data: [],
        totalItemCount: 0,
        count: 0,
        pageCount: 0,
        currentPage: 1,
      },
      pageSize: 10,
      pageIndex: 1,
      noResultsFound: false,

      showSponsorAdFormModal: false,
      showDeleteModal: false,
      loading: false,

      editModel: null,
    };
  },
  async mounted() {
    await this.getSponsorsList();
    await this.getSponsorAdCategoriesList();
  },
  computed: {
    filtersConfig() {
      return [
        {
          name: 'advertisers',
          label: this.$t('advertisers'),
          component: 'FilterMultiSelect',
          options: this.filters.sponsors,
          placeholder: this.$t('select'),
          fieldtext: 'name',
          fieldvalue: 'id',
          clearable: false,
          class: 'col-sm-12 col-md-4 col-lg-3',
          initialValue: this.selectedFilters.sponsor,
          onChanged: this.onSponsorChanged,
        },
        {
          name: 'adCategory',
          label: this.$t('adCategory'),
          component: 'FilterMultiSelect',
          options: this.filters.sponsorAdCategories,
          placeholder: this.$t('select'),
          fieldtext: 'name',
          fieldvalue: 'id',
          clearable: false,
          class: 'col-sm-12 col-md-4 col-lg-3',
          initialValue: this.selectedFilters.sponsorAdCategory,
          onChanged: this.onAdCategoryChanged,
        },
      ];
    },
  },
  methods: {
    openCreateModal() {
      this.showSponsorAdFormModal = true;
      this.editModel = null;
    },
    downloadAd(data) {
      let a = document.createElement('a');
      document.body.appendChild(a);
      a.style = 'display: none';
      a.href = data.mediaFile?.publicUrl || data.imageUrl;
      a.download = data.mediaFile?.fileName || data.name;
      a.target = data.mediaFile?.publicUrl ? a.target : '__blank'
      a.click();
      window.URL.revokeObjectURL(a.href);
    },
    openDeleteModal(data) {
      this.editModel = { ...data };
      this.editModel.name = this.editModel.keyword;
      this.showDeleteModal = true;
    },
    openEditModal(data) {
      this.showSponsorAdFormModal = true;
      this.editModel = { ...data };
    },
    onDelete() {
      _deleteSponsorAd(this.editModel.id)
        .then(async () => {
          this.ShowSuccessToast(this.$t('successfullyDeleted'));
          this.showDeleteModal = false;
          await this.search();
          this.editModel = null;
        })
        .catch((error) => this.ShowErrorToast(error.response.data.message));
    },
    async search(pageIndex = null, pageSize = null) {
      const isValid = await this.$refs.sponsorFormRef.validate();

      if (!isValid) {
        return;
      }

      this.pageSize = pageSize || this.pageSize;
      this.pageIndex = pageIndex || this.pageIndex;

      const payload = {
        sponsorId: this.selectedFilters.sponsor ? this.selectedFilters.sponsor[0].id : null,
        sponsorAdCategoryId: this.selectedFilters.sponsorAdCategory ? this.selectedFilters.sponsorAdCategory[0].id : null,
        pageIndex: this.pageIndex,
        pageSize: this.pageSize,
      };

      await _getSponsorAds(payload)
        .then(({ data }) => {
          this.sponsorAds = data;
          this.noResultsFound = !this.sponsorAds.count;
        })
        .catch((error) => this.ShowErrorToast(error.response.data.message));
    },
    onSponsorChanged(value) {
      this.selectedFilters.sponsor = [value];
    },
    onAdCategoryChanged(value) {
      this.selectedFilters.sponsorAdCategory = [value];
    },
    async getSponsorsList() {
      await _getSponsorsList()
        .then(({ data }) => {
          this.filters.sponsors = data;
          this.noResultsFound = !this.filters.sponsors.count;
        })
        .catch((error) => this.ShowErrorToast(error.response.data.message));
    },
    async getSponsorAdCategoriesList() {
      await _getSponsorAdCategoriesList()
        .then(({ data }) => {
          this.filters.sponsorAdCategories = data;
          this.noResultsFound = !this.filters.sponsorAdCategories.count;
        })
        .catch((error) => this.ShowErrorToast(error.response.data.message));
    }
  },
};
</script>
<style lang="scss" scoped>
</style>
