import { apiTurnos } from '@/core/api/';
import {
    serialize
  } from 'object-to-formdata'

export const getSponsors = (payload) => apiTurnos.get('/api/sponsor/search',{ params : { ...payload }});

export const getSponsor = (payload) => apiTurnos.get(`/api/sponsor/${payload}`);

export const createOrUpdateSponsor = (payload) => apiTurnos.post('/api/sponsor', payload);

export const deleteSponsor = (payload) => apiTurnos.delete(`/api/sponsor/${payload}`);

// Begin Ads Maintenance

export const getSponsorAds = (payload) => apiTurnos.get('/api/sponsor/ads/search',{ params : { ...payload }});

export const getSponsorsList = () => apiTurnos.get('/api/sponsor/list');

export const getSponsorAdCategoriesList = () => apiTurnos.get('/api/sponsor/ads/categories/list');

export const getSponsorAd = (payload) => apiTurnos.get(`/api/sponsor/ads/${payload}`);

export const createSponsorAd = (model) => {
    const payload = serialize(model, {
        indices: true,
        nullsAsUndefineds: true
    });

    return apiTurnos.post('/api/sponsor/ads', payload, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    },);
}

export const updateSponsorAd = (model) => {
    const payload = serialize(model, {
        indices: true,
        nullsAsUndefineds: true
    });

    return apiTurnos.put('/api/sponsor/ads', payload, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    },);
}

export const deleteSponsorAd = (payload) => apiTurnos.delete(`/api/sponsor/ads/${payload}`);

export const deleteSponsorAdMediaFile = (payload) => apiTurnos.delete(`/api/sponsor/ads/${payload}/file`);

export const getAssingLocations = (id) => apiTurnos.get(`/api/sponsor/ads/${id}/assign/locations`);

export const assingLocation = (id, locationConfigurationId) => apiTurnos.post(`/api/sponsor/ads/${id}/assign/location`, locationConfigurationId);

export const unassingLocation = (id, locationConfigurationId) => apiTurnos.post(`/api/sponsor/ads/${id}/unassign/location`, locationConfigurationId);

// End Ads Maintenance
