<template>
  <validation-observer ref="sponsorFormRef" novalidate>
    <div class="row">
      <div class="col-md-12">
        <validation-provider v-slot="{ errors }" rules="required|max:200">
          <base-input
            v-model="model.name"
            :error="errors[0]"
            :error-msg="$t('invalidField')"
            :label="$t('sponsorName')"
            :max-length="200"
          />
        </validation-provider>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12 col-md-6">
        <b-form-checkbox
          v-model="model.active"
          class="mt-2 chk-available"
          switch
        >
          {{ $t('available') }}
        </b-form-checkbox>
      </div>
      <div class="col-sm-12 col-md-6">
        <button
          class="btn btn-primary float-right"
          variant="primary"
          @click="onSubmit()"
        >
          <i class="far fa-save" /> {{ $t('save') }}
        </button>
      </div>
    </div>
  </validation-observer>
</template>

<script>
import BaseInput from '@/components/BaseInput.vue';

import { createOrUpdateSponsor as _createOrUpdateSponsor } from '@/services/SponsorService';

export default {
  name: 'SponsorForm',
  components: { BaseInput },
  props: {
    value: {
      type: Object,
      required: false
    },
    companyId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {  
      model: !this.value ? {
        name: this.name,
        active: true,
      } : {
        sponsorId: this.value.sponsorId,
        name: this.value.name,
        active: this.value.active,
      },
    };
  },
  mounted() {
  },
  methods: {
    async onSubmit() {
      const isValid = await this.$refs.sponsorFormRef.validate();

      if (!isValid) {
        return;
      }

      const payload = {
        sponsorId: this.model.sponsorId,
        name: this.model.name,
        active: this.model.active
      };

      await _createOrUpdateSponsor(payload)
        .then(() => {
          this.ShowSuccessSaveToast();
          this.$emit('submitted');
          this.$emit('close');
        })
        .catch((error) => this.ShowErrorToast(error.response.data.message));
    },
  },
};
</script>
<style lang="scss" scoped>
</style>
