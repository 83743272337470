import { apiTurnos } from '@/core/api/';
import {
    serialize
  } from 'object-to-formdata'

export const getAds = (payload) => apiTurnos.get('/api/clientad/search',{ params : { ...payload }});

export const getLoginAds = (payload) => apiTurnos.get('/api/clientad/login/search',{ params : { ...payload }});

export const getLoginAd = (payload) => apiTurnos.get(`/api/clientad/login/${payload}`);

export const createLoginAd = (model) => {
    const payload = serialize(model, {
        indices: true,
        nullsAsUndefineds: true
    });

    return apiTurnos.post('/api/clientad/login', payload, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    },);
}

export const updateLoginAd = (model) => {
    const payload = serialize(model, {
        indices: true,
        nullsAsUndefineds: true
    });

    return apiTurnos.put('/api/clientad/login', payload, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    },);
}

export const deleteLoginAd = (payload) => apiTurnos.delete(`/api/clientad/login/${payload}`);

export const deleteLoginAdMediaFile = (payload) => apiTurnos.delete(`/api/clientad/login/${payload}/file`);


export const createAd = (model) => {
    const payload = serialize(model, {
        indices: true,
        nullsAsUndefineds: true
    });

    return apiTurnos.post('/api/clientad', payload, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    },);
}

export const updateAd = (model) => {
    const payload = serialize(model, {
        indices: true,
        nullsAsUndefineds: true
    });

    return apiTurnos.put('/api/clientad', payload, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    },);
}

export const deleteAd = (payload) => apiTurnos.delete(`/api/clientad/${payload}`);
export const deleteAdMediaFile = (payload) => apiTurnos.delete(`/api/clientad/${payload}/file`);

export const getSaleSpacesAvailable = (payload) =>
  apiTurnos.get('/api/clientad/space/search', { params: { ...payload } });
