<template>
  <section class="pt-2">
    <small
      class="pl-3 cursor-pointer"
      @click="$router.push({ name: 'SponsorsAds' })"
    >
      <i class="far fa-arrow-alt-circle-left" /> {{ $t('return') }}
    </small>
    <content-header :title="$t('assignAnnouncementLocations')" />

    <div class="container-fluid">
      <div class="row">
        <div class="col-md-6">
          <div class="row">
            <div class="col-12">
              <div class="filter">
                <div class="row">
                <div class="col-12 col-md-6">
                  <filter-select
                    v-model="companyId"
                    fieldtext="text"
                    fieldvalue="value"
                    :label="$t('company')"
                    :options="companies"
                  />
                </div>
              </div>
              </div>

            </div>
            <div class="col-12">
              <div
                v-if="!companyId"
                class="box"
                style="
                  display: flex;
                  justify-content: center;
                  align-items: center;
                "
              >
                <h3>{{ $t('selectCompany') }}</h3>
              </div>
              <div v-else class="box">
                <h5>{{ $t('locations') }}</h5>
                <hr />
                <div class="chips">
                  <div
                    class="chip"
                    :class="{ disabled: item.disabled }"
                    v-for="item in toAssing"
                    :key="item.id"
                    @click="assing(item)"
                  >
                    {{ item.name }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6" style="margin-top: 68px">
          <div class="box mt-5">
            <h5>{{ $t('selectedLocations') }}</h5>
            <hr />
            <div class="chips">
              <div
                class="chip active"
                v-for="item in assingedLocations"
                :key="item.id"
                @click="onUnassing(item.id)"
              >
                {{ item.name }}
              </div>
            </div>
          </div>
        </div>
        <confirmation-modal
          v-model="showDeleteModal"
          text="areYouSureYouWantToDelete"
          confirmation-text="delete"
          @on-confirm="unassing()"
          @on-reject="showDeleteModal = false"
        />
      </div>
    </div>
  </section>
</template>
<script>
import { getCompanies as _getCompanies } from '@/services/CompanyService';
import { getLocationListForKiosk as _getLocationListForKiosk } from '@/services/LocationService';
import {
  getAssingLocations as _getAssingLocations,
  assingLocation as _assingLocation,
  unassingLocation as _unassingLocation,
} from '@/services/SponsorService';
import ConfirmationModal from '@/components/basics/modal/ConfirmationModal.vue';
import contentHeader from '@/components/Header';

export default {
  name: 'AssignLocation',
  components: {
    ConfirmationModal,
    contentHeader,
  },
  data() {
    return {
      companyId: null,
      companies: [],
      assingedLocations: [],
      toAssingList: [],
      sponsorId: null,
      showDeleteModal: false,
      seletedLocationConfigurationId: null,
    };
  },
  async created() {
    this.sponsorId = Number(this.$route.params.id);
    const { data: companies } = await _getCompanies();
    this.companies = companies;

    const { data: assingedLocations } = await _getAssingLocations(
      this.sponsorId,
    );
    this.assingedLocations = assingedLocations;
  },
  watch: {
    async companyId(value) {
      if (value) {
        const { data } = await _getLocationListForKiosk(value);
        this.toAssingList = data;
      } else {
        this.toAssingList = [];
      }
    },
  },
  computed: {
    toAssing() {
      return this.toAssingList.map((x) => ({
        ...x,
        disabled: this.assingedLocations.some((al) => al.id == x.id),
      }));
    },
  },
  methods: {
    async assing(location) {
      await _assingLocation(this.sponsorId, location.id);
      this.ShowSuccessToast(this.$t('operationCompleted'));
      const companyName = this.companies.find(
        (x) => x.value == this.companyId,
      ).text;
      const name = `${companyName} - ${location.name}`;
      this.assingedLocations.unshift({ id: location.id, name });
    },
    onUnassing(locationConfigurationId) {
      this.showDeleteModal = true;
      this.seletedLocationConfigurationId = locationConfigurationId;
    },
    async unassing() {
      await _unassingLocation(
        this.sponsorId,
        this.seletedLocationConfigurationId,
      );
      this.ShowSuccessToast(this.$t('operationCompleted'));
      const indexToDeleted = this.assingedLocations.findIndex(
        (x) => x.id == this.seletedLocationConfigurationId,
      );
      this.assingedLocations.splice(indexToDeleted, 1);
      this.showDeleteModal = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.filter {
  background-color: #fff;
  border-radius: 14px;
  padding: 10px 20px;
  margin-bottom: 10px;
}
.box {
  background-color: #fff;
  min-height: 400px;
  border-radius: 14px;
  padding: 20px;
  .chips {
    display: flex;
    flex-wrap: wrap;
    .chip {
      background-color: #d9d9d9;
      border-radius: 14px;
      padding: 4px 12px;
      margin: 4px 8px;
      cursor: pointer;
      &.disabled {
        background-color: #b7b7b7;
        pointer-events: none;
      }
      &:hover,
      &.active {
        background-color: #ff8105;
        color: #fff;
      }
    }
  }
}
</style>
