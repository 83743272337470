<template>
  <validation-observer ref="form" novalidate>
    <div class="row">
      <div class="col-md-12">
        <div class="card" style="border: white">
          <div class="row">
            <div class="col-md-12" />
            <div class="col-md-12">
              <validation-provider v-slot="{ errors }" rules="required">
                <filter-select
                  v-model="model.companyId"
                  :clearable="false"
                  :error="errors[0]"
                  :error-msg="$t('mustSelectOne')"
                  fieldtext="text"
                  fieldvalue="value"
                  :label="$t('company')"
                  :options="options.companies"
                />
              </validation-provider>
            </div>
            <div class="col-md-12">
              <validation-provider v-slot="{ errors }" rules="required">
                <filter-select
                  v-model="model.locationConfigurationId"
                  :clearable="false"
                  :error="errors[0]"
                  :error-msg="$t('mustSelectOne')"
                  fieldtext="name"
                  fieldvalue="id"
                  :label="$t('localization')"
                  :options="options.locations"
                />
              </validation-provider>
            </div>
            <div class="col-md-12">
              <custom-image-input
                v-model="model.media"
                accept=".jpg,.jpeg,.png,.gif,.svg,.mp4"
                :auto-delete="false"
                :file="model.mediaFile"
                :label="$t('adFile')"
                :max-height="1920"
                :max-size="10"
                :max-width="1920"
                @clearFile="onDeleteLogo"
              />
            </div>
          </div>
          <div class="row">
            <validation-provider
              v-slot="{ errors }"
              class="col-sm-12 col-md-4"
              :rules="`required`"
            >
              <filter-numeric
                v-model="model.adsTime"
                :error="errors[0]"
                icon="fas fa-clock mr-2"
                :max="300"
                :min="5"
                title="duration"
              />
            </validation-provider>
            <validation-provider
              v-slot="{ errors }"
              class="col-sm-12 col-md-4"
              :rules="`required`"
            >
              <filter-numeric
                v-model="model.adsOrder"
                :error="errors[0]"
                icon="fas fa-copy mr-2"
                :max="15"
                :min="1"
                title="order"
              />
            </validation-provider>
            <validation-provider
              v-slot="{ errors }"
              class="col-sm-12 col-md-4"
              :rules="`required`"
            >
              <filter-numeric
                v-model="model.videoVolume"
                :error="errors[0]"
                icon="fas fa-volume mr-2"
                :max="100"
                :min="1"
                title="videoVolume"
              />
            </validation-provider>
          </div>
          <div v-if="model.id" class="row">
            <validation-provider
              v-slot="{ errors }"
              class="col-sm-12 col-md-4"
              :rules="`required`"
            >
              <filter-numeric
                v-model="model.monitor"
                :error="errors[0]"
                icon="fas fa-tv mr-2"
                :max="9999"
                :min="1"
                title="monitor"
              />
            </validation-provider>
            <validation-provider
              v-slot="{ errors }"
              class="col-sm-12 col-md-4"
              :rules="`required`"
            >
              <filter-numeric
                v-model="model.groupId"
                :error="errors[0]"
                icon="fas fa-copy mr-2"
                :max="9999"
                :min="1"
                title="group"
              />
            </validation-provider>
          </div>
          <div class="row mt-2">
            <div class="col-sm-12 col-md-6">
              <b-form-checkbox
                v-model="model.active"
                class="mt-2 chk-available"
                switch
              >
                {{ $t('active') }}
              </b-form-checkbox>
            </div>
            <div class="col-sm-12 col-md-6">
              <button
                class="btn btn-primary float-right"
                variant="primary"
                @click="onSubmit"
              >
                <i class="far fa-save" /> {{ $t('save') }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </validation-observer>
</template>

<script>
import CustomImageInput from '@/components/CustomImageInput';
import { getCompanies as _getCompanies } from '@/services/CompanyService';
import { getLocationList as _getLocationList } from '@/services/LocationService';
import {
  createAd as _createAd,
  deleteAdMediaFile as _deleteAdMediaFile,
  updateAd as _updateAd,
} from '@/services/AdService';

export default {
  name: 'AdsForm',
  components: {
    CustomImageInput,
  },
  props: {
    value: {
      type: Object,
      required: false,
    },
  },
  watch: {
    'value.companyId': {
      handler(value) {
        this.locationConfiguration = [];
        this.company = [];
        this.options.locations = [];
        if (value) {
          this.company = value;
          this.getLocations(this.company);
        }
      },
      immediate: true,
    },
    'model.companyId': {
      handler(value) {
        this.locationConfiguration = [];
        this.company = [];
        this.options.locations = [];
        if (value) {
          this.company = value;
          this.getLocations(this.company);
        }
      },
      immediate: true,
    },
  },
  data() {
    return {
      options: {
        companies: [],
        locations: [],
      },
      model: this.value
        ? this.value
        : {
            companyId: null,
            locationConfigurationId: null,
            id: null,
            key: null,
            adsTime: 15,
            adsOrder: 1,
            monitor: 1,
            groupId: 100,
            videoVolume: 50,
            active: true,
            alternativeKey: null,
            blobPath: null,
            media: null,
            mediaFile: null,
          },
    };
  },
  computed: {},
  async mounted() {
    await this.getCompanies();
  },
  methods: {
    async getCompanies() {
      await _getCompanies()
        .then((response) => {
          this.options.companies = response.data || [];
        })
        .catch((error) => this.ShowErrorToast(error.response.data.message));
    },
    async getLocations(companyId) {
      await _getLocationList(companyId)
        .then((response) => {
          this.options.locations = response.data;
        })
        .catch((error) => this.ShowErrorToast(error.response.data.message));
    },

    async onDeleteLogo() {
      if (this.model.blobPath) {
        await _deleteAdMediaFile(this.model.id);

        this.ShowSuccessSaveToast();
      }

      this.model.media = null;
      this.model.mediaFile = null;
      this.model.blobPath = null;
    },

    async onSubmit(next = false) {
      if (!(await this.$refs.form.validate())) return;

      const payload = { ...this.model };
      payload.mediaFile = this.model.media;
      if (!payload.id) {
        await _createAd(payload).then(() => {
          if (next) this.$emit('go-to', 1);
          this.ShowSuccessSaveToast();
          this.$emit('close');
          this.$emit('submitted');
        });
      } else {
        await _updateAd(payload)
          .then(() => {
            this.ShowSuccessSaveToast();
            this.$emit('close');
            this.$emit('submitted');
          })
          .catch((error) => this.ShowErrorToast(error.response.data.message));
      }
    },
  },
};
</script>
