<template>
  <validation-observer ref="form" novalidate>
    <div class="row">
      <div class="col-md-12">
        <div class="card" style="border: white">
          <div class="row">
            <div class="col-md-12" />
            <div class="col-md-12">
              <validation-provider v-slot="{ errors }" rules="required">
                <filter-select
                  v-model="model.sponsorId"
                  :clearable="false"
                  :error="errors[0]"
                  :error-msg="$t('mustSelectOne')"
                  fieldtext="name"
                  fieldvalue="id"
                  :label="$t('sponsorName')"
                  :options="options.sponsors"
                />
              </validation-provider>
            </div>
            <div class="col-md-12">
              <validation-provider v-slot="{ errors }" rules="required">
                <filter-select
                  v-model="model.sponsorAdCategoryId"
                  :clearable="false"
                  :error="errors[0]"
                  :error-msg="$t('mustSelectOne')"
                  fieldtext="name"
                  fieldvalue="id"
                  :label="$t('adCategory')"
                  :options="options.sponsorAdCategories"
                />
              </validation-provider>
            </div>
            <div class="col-md-12">
              <custom-image-input
                v-model="model.media"
                accept=".jpg,.jpeg,.png,.gif,.svg,.mp4"
                :auto-delete="false"
                :file="model.mediaFile"
                :label="$t('adFile')"
                :max-height="1080"
                :max-size="10"
                :max-width="1920"
                @clearFile="onDeleteLogo"
              />
            </div>
          </div>
          <div class="row">
            <validation-provider
              v-slot="{ errors }"
              class="col-sm-12 col-md-4"
              :rules="`required`"
            >
              <filter-numeric
                v-model="model.adsTime"
                :error="errors[0]"
                icon="fas fa-clock mr-2"
                :max="300"
                :min="5"
                title="duration"
              />
            </validation-provider>
            <validation-provider
              v-slot="{ errors }"
              class="col-sm-12 col-md-4"
              :rules="`required`"
            >
              <filter-numeric
                v-model="model.adsOrder"
                :error="errors[0]"
                icon="fas fa-copy mr-2"
                :max="15"
                :min="1"
                title="order"
              />
            </validation-provider>
            <validation-provider
              v-slot="{ errors }"
              class="col-sm-12 col-md-4"
              :rules="`required`"
            >
              <filter-numeric
                v-model="model.videoVolume"
                :error="errors[0]"
                icon="fas fa-volume mr-2"
                :max="100"
                :min="1"
                title="videoVolume"
              />
            </validation-provider>
          </div>
          <div v-if="model.id" class="row">
            <validation-provider
              v-slot="{ errors }"
              class="col-sm-12 col-md-4"
              :rules="`required`"
            >
              <filter-numeric
                v-model="model.monitor"
                :error="errors[0]"
                icon="fas fa-tv mr-2"
                :max="9999"
                :min="1"
                title="monitor"
              />
            </validation-provider>
            <validation-provider
              v-slot="{ errors }"
              class="col-sm-12 col-md-4"
              :rules="`required`"
            >
              <filter-numeric
                v-model="model.adGroupId"
                :error="errors[0]"
                icon="fas fa-copy mr-2"
                :max="9999"
                :min="1"
                title="group"
              />
            </validation-provider>
          </div>
          <div v-if="model.id" class="row">
            <div class="col-sm">
              <base-datetime-picker
                :clearable="false"
                format="dd/MMM/yyyy"
                :title="$t('startDate')"
                type="date"
                :value="model.adBeginDate"
                @change="onBeginDateChange($event)"
              />
            </div>
            <div class="col-sm">
              <base-datetime-picker
                :clearable="false"
                format="dd/MMM/yyyy"
                :min-datetime="model.adBeginDate"
                :title="$t('expiration')"
                type="date"
                :value="model.adEndDate"
                @change="model.adEndDate = $event"
              />
            </div>
          </div>
          <div class="row mt-2">
            <div class="col-sm-12 col-md-6">
              <b-form-checkbox
                v-model="model.active"
                class="mt-2 chk-available"
                switch
              >
                {{ $t('active') }}
              </b-form-checkbox>
            </div>
            <div class="col-sm-12 col-md-6">
              <button
                class="btn btn-primary float-right"
                variant="primary"
                @click="onSubmit"
              >
                <i class="far fa-save" /> {{ $t('save') }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </validation-observer>
</template>

<script>
import CustomImageInput from '@/components/CustomImageInput';
import {
  createSponsorAd as _createSponsorAd,
  deleteSponsorAdMediaFile as _deleteSponsorAdMediaFile,
  updateSponsorAd as _updateSponsorAd,
} from '@/services/SponsorService';
import {
  getSponsorsList as _getSponsorsList,
  getSponsorAdCategoriesList as _getSponsorAdCategoriesList,
} from '@/services/SponsorService';

export default {
  name: 'SponsorsAdsCreate',
  components: {
    CustomImageInput,
  },
  props: {
    value: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      options: {
        sponsorAdCategories: [],
        sponsors: [],
      },
      model: this.value
        ? this.value
        : {
            sponsorId: null,
            sponsorAdCategoryId: null,
            id: null,
            key: null,
            adsTime: 15,
            adsOrder: 1,
            videoVolume: 50,
            active: true,
            alternativeKey: null,
            blobPath: null,
            media: null,
            mediaFile: null,
            adGroupId: 100,
            monitor: 1,
            adBeginDate: null,
            adEndDate: null,
          },
    };
  },
  computed: {},
  watch: {},
  async mounted() {
    await this.getSponsorAdCategoriesList();
    await this.getSponsorsList();
  },
  methods: {
    onBeginDateChange($event) {
      this.model.adBeginDate = $event;
      if (this.model.adEndDate < this.model.adBeginDate) {
        this.model.adEndDate = null;
      }
    },
    async onDeleteLogo() {
      if (this.model.blobPath) {
        await _deleteSponsorAdMediaFile(this.model.id);

        this.ShowSuccessSaveToast();
      }

      this.model.media = null;
      this.model.mediaFile = null;
      this.model.blobPath = null;
    },
    async getSponsorAdCategoriesList() {
      await _getSponsorAdCategoriesList()
        .then(({ data }) => {
          this.options.sponsorAdCategories = data;
        })
        .catch((error) => this.ShowErrorToast(error.response.data.message));
    },
    async getSponsorsList() {
      await _getSponsorsList()
        .then(({ data }) => {
          this.options.sponsors = data;
        })
        .catch((error) => this.ShowErrorToast(error.response.data.message));
    },
    async onSubmit(next = false) {
      if (!(await this.$refs.form.validate())) return;

      const payload = { ...this.model };
      payload.mediaFile = this.model.media;
      if (!payload.id) {
        await _createSponsorAd(payload).then(() => {
          if (next) this.$emit('go-to', 1);
          this.ShowSuccessSaveToast();
          this.$emit('close');
          this.$emit('submitted');
        });
      } else {
        await _updateSponsorAd(payload)
          .then(() => {
            this.ShowSuccessSaveToast();
            this.$emit('close');
            this.$emit('submitted');
          })
          .catch((error) => this.ShowErrorToast(error.response.data.message));
      }
    },
  },
};
</script>
