<template>
  <section>
    <content-header v-if="showMaintenance" :title="$t('adsMaintenance')" />
    <div v-if="showMaintenance" class="container-fluid">
      <div class="main-page-container">
        <div class="row">
          <sub-header :title="$t('filterAds')" />
        </div>
        <filter-manager
          ref="filterComponent"
          v-model="selectedFilters"
          class="mb-4"
          :display-advanced="false"
          :filters="filtersConfig"
          :search-text="$t('filter')"
          @search="getAds(1)"
        />
      </div>

      <separator class="my-4" :text="$t('listOfAds')" />
      <div class="row mt-4 mb-3">
        <div class="col-sm-12">
          <base-filled-button
            class="float-right"
            icon-class="fas fa-plus"
            :on-click="() => openCreateModal()"
            :text="$t('create')"
          />
        </div>
      </div>
      <CustomNotFound v-if="noResultsFound" :text="$t('noResultsFound')" />
      <div class="row">
        <div v-for="(ad, index) in ads.data" :key="index" class="mt-3 col-12">
          <search-result
            :value="ad"
            @on-delete="openDeleteModal(ad)"
            @on-edit="openEditModal(ad)"
          />
        </div>
      </div>
      <custom-pagination
        v-if="ads.count > 0"
        id="pgPager"
        class="my-4"
        :data="ads"
        :page-size="pageSize"
        @pagination-go-page="getAds($event)"
        @pagination-rows-per-page="getAds(pageIndex, $event)"
      />
    </div>
    <BigLoader v-if="loading" />
    <AlertMessage
      v-if="!showMaintenance && !loading"
      :message="$t('selectOfficeForTheMaintenance')"
      style="margin-top: 150px"
    />
    <custom-modal
      v-model="showAdFormModal"
      size="md"
      :title="!editModel ? $t('createAd') : $t('editAd')"
    >
      <AdForm
        v-if="showAdFormModal == true"
        v-model="editModel"
        @close="showAdFormModal = false"
        @submitted="search"
      />
    </custom-modal>

    <DeleteModal
      v-model="showDeleteModal"
      :display-label="false"
      error-message="requiredField"
      :item="editModel"
      name="keyword"
      @on-confirm="onDelete()"
    />
  </section>
</template>

<script>
import CustomPagination from '@/components/CustomPagination';
import CustomNotFound from '@/components/basics/alerts/CustomNotFound';
import CustomModal from '@/components/basics/modal/CustomModal';
import SearchResult from './components/SearchResult';
import FilterManager from '@/components/FilterManager';
import { getCompanies as _getCompanies } from '@/services/CompanyService';
import BigLoader from '@/components/BigLoader';
import contentHeader from '@/components/Header';
import SubHeader from '@/components/SubHeader';
import IdentityPermission from '@/constants/IdentityPermission';
import { getLocationList as _getLocationList } from '@/services/LocationService';
import Separator from '@/components/Separator';
import { modulesName } from '@/store';
import AdForm from './components/AdForm';
import AlertMessage from '@/components/basics/alerts/AlertMessage';
import { getAds as _getAds, deleteAd as _deleteAd } from '@/services/AdService';
import { mapGetters } from 'vuex';
import DeleteModal from '@/components/DeleteModal';
export default {
  name: 'Ads',
  components: {
    CustomPagination,
    CustomNotFound,
    SearchResult,
    FilterManager,
    contentHeader,
    SubHeader,
    Separator,
    AdForm,
    AlertMessage,
    CustomModal,
    BigLoader,
    DeleteModal,
  },
  data() {
    return {
      filters: {
        locations: [],
        companies: [],
        keywords: [],
      },
      selectedFilters: {
        location: [],
        company: [],
        keyword: [],
      },
      ads: {
        data: [],
        totalItemCount: 0,
        count: 0,
        pageCount: 0,
        currentPage: 1,
      },
      pageSize: 10,
      pageIndex: 1,
      noResultsFound: false,
      showDeleteModal: false,
      showMaintenance: false,
      loading: false,
      showAdFormModal: false,
      editModel: null,
    };
  },
  mounted() {
    this.filters.keywords = [
      { value: 'image', text: this.$t('image') },
      { value: 'video', text: this.$t('video') },
    ];
    if (!this.isMultiOffice) {
      this.getCompanies();
      this.showMaintenance = true;
    } else if (this.isMultiOffice && this.currentActiveLocation) {
      this.showMaintenance = true;
    }
  },
  computed: {
    ...mapGetters(modulesName.userModuleName, ['currentActiveLocation']),
    filtersConfig() {
      if (!this.isMultiOffice) {
        return [
          {
            rules: '',
            name: 'company',
            label: this.$t('company'),
            component: 'FilterMultiSelect',
            options: this.filters.companies,
            placeholder: `-- ${this.$t('all')} --`,
            fieldtext: 'text',
            fieldvalue: 'value',
            clearable: true,
            class: 'col-sm-12 col-md-4 col-lg-3',
            error: this.$t('mustselectcompany'),
            initialValue: this.selectedFilters.company,
            onChanged: this.onCompanyChanged,
          },
          {
            rules: '',
            name: 'location',
            label: this.$t('localization'),
            component: 'FilterMultiSelect',
            options: this.filters.locations,
            placeholder: `-- ${this.$t('all')} --`,
            fieldtext: 'name',
            fieldvalue: 'id',
            clearable: true,
            class: 'col-sm-12 col-md-4 col-lg-3',
            initialValue: this.selectedFilters.location,
            onChanged: this.onLocationChanged,
          },
          {
            rules: '',
            name: 'keyword',
            label: this.$t('keyword'),
            component: 'FilterMultiSelect',
            options: this.filters.keywords,
            placeholder: `-- ${this.$t('all')} --`,
            fieldtext: 'text',
            fieldvalue: 'value',
            clearable: true,
            class: 'col-sm-12 col-md-4 col-lg-3',
            initialValue: this.selectedFilters.keyword,
            onChanged: this.onKeywordChanged,
          },
        ];
      }

      return [
        {
          rules: '',
          name: 'keyword',
          label: this.$t('keyword'),
          component: 'FilterMultiSelect',
          options: this.filters.keywords,
          placeholder: `-- ${this.$t('all')} --`,
          fieldtext: 'text',
          fieldvalue: 'value',
          clearable: true,
          class: 'col-sm-12 col-md-4 col-lg-3',
          initialValue: this.selectedFilters.keyword,
          onChanged: this.onKeywordChanged,
        },
      ];
    },
    isMultiOffice() {
      return this.userHasPermissions(IdentityPermission.multi.office);
    },
  },
  watch: {
    currentActiveLocation() {
      this.showMaintenance = false;
      this.loading = true;
      this.noResultsFound = false;
      setTimeout(() => {
        this.selectedFilters = {
          location: [],
          company: [],
          keyboard: [],
        };
        this.ads = {
          data: [],
          totalItemCount: 0,
          count: 0,
          pageCount: 0,
          currentPage: 1,
        };
        this.loading = false;
        this.showMaintenance = true;
      }, 1200);
    },
  },
  methods: {
    openCreateModal() {
      this.editModel = null;
      this.showAdFormModal = true;
    },
    downloadAd(data) {
      let a = document.createElement('a');
      document.body.appendChild(a);
      a.style = 'display: none';
      a.href = data.mediaFile?.publicUrl || data.imageUrl;
      a.download = data.mediaFile?.fileName || data.name;
      a.click();
      window.URL.revokeObjectURL(a.href);
    },
    openDeleteModal(data) {
      this.showDeleteModal = true;
      this.editModel = { ...data };
      this.editModel.name = this.editModel.keyword;
    },
    openEditModal(data) {
      this.showAdFormModal = true;
      this.editModel = { ...data };
    },
    onDelete() {
      _deleteAd(this.editModel.id)
        .then(async () => {
          this.ShowSuccessToast(this.$t('successfullyDeleted'));
          this.showDeleteModal = false;
          await this.search();
          this.editModel = null;
        })
        .catch((error) => this.ShowErrorToast(error.response.data.message));
    },
    async getCompanies() {
      await _getCompanies()
        .then((response) => {
          this.filters.companies = response.data || [];
        })
        .catch((error) => this.ShowErrorToast(error.response.data.message));
    },
    async getLocations(companyId) {
      await _getLocationList(companyId)
        .then((response) => {
          this.filters.locations = response.data;
        })
        .catch((error) => this.ShowErrorToast(error.response.data.message));
    },
    onCompanyChanged(value) {
      this.selectedFilters.location = [];
      this.selectedFilters.company = [];
      this.filters.locations = [];
      if (value) {
        this.selectedFilters.company = [value];
        this.getLocations(this.selectedFilters.company[0].value);
      }
    },
    onLocationChanged(value) {
      if (!value) return (this.selectedFilters.location = []);

      this.selectedFilters.location = [value];
    },
    onKeywordChanged(value) {
      if (!value) return (this.selectedFilters.keyword = []);

      this.selectedFilters.keyword = [value];
    },
    async getAds(pageIndex = null, pageSize = null) {
      this.pageSize = pageSize || this.pageSize;
      this.pageIndex = pageIndex || this.pageIndex;

      const payload = {
        CompanyId:
          this.selectedFilters.company.length > 0
            ? this.selectedFilters.company[0].value
            : null,
        LocationConfigurationId: this.isMultiOffice
          ? Number(this.currentActiveLocation.locationConfigurationId)
          : this.selectedFilters.location.length > 0
          ? this.selectedFilters.location[0].id
          : null,
        Keyword:
          this.selectedFilters.keyword.length > 0
            ? this.selectedFilters.keyword[0].value
            : null,
        PageIndex: this.pageIndex,
        PageSize: this.pageSize,
      };

      await _getAds(payload)
        .then(({ data }) => {
          this.ads = data;
          this.noResultsFound = !this.ads.count;
        })
        .catch((error) => this.ShowErrorToast(error.response.data.message));
    },
  },
};
</script>
