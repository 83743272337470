<template>
  <div class="p-4 listview card border-light">
    <div class="row">
      <div class="col-12 col-sm-6 col-md-3">
        <div
          v-if="!isMultiOffice"
          class="cell"
        >
          <label>{{ $t('company') }}: </label>
          <span>{{ value.companyName || '-' }}</span>
        </div>
        <div
          v-if="!isMultiOffice"
          class="cell"
        >
          <label>{{ $t('localization') }}: </label>
          <span>{{ value.locationName || '-' }}</span>
        </div>
        <div class="cell">
          <label>{{ $t('description') }}: </label>
          <span>{{ value.alternateText || '-' }}</span>
        </div>
        <div class="cell">
          <label>{{ $t('keyword') }}: </label>
          <span class="text-capitalize">{{ value.keyword || '-' }}</span>
        </div>
      </div>
      <div class="col-12 col-sm-6 col-md-3">
        <div class="cell">
          <label>{{ $t('url') }}: </label>
          <span>{{ value.navigateUrl || '-' }}</span>
        </div>
        <div class="cell">
          <label>{{ $t('time') }}: </label>
          <span>{{ value.adsTime || '-' }}</span>
        </div>
        <div class="cell">
          <label>{{ $t('group') }}: </label>
          <span>{{ value.groupId || '-' }}</span>
        </div>
        <div class="cell">
          <label>{{ $t('order') }}: </label>
          <span>{{ value.adsOrder || '-' }}</span>
        </div>
      </div>
      <div class="col-12 col-sm-6 col-md-3">
        <div class="cell">
          <label>{{ $t('monitor') }}: </label>
          <span>{{ value.monitor || '-' }}</span>
        </div>
        <div class="cell">
          <label>{{ $t('volume') }}: </label>
          <span>{{ value.videoVolume || '-' }}</span>
        </div>
        <div class="cell">
          <label>{{ $t('available') }}: </label>
          <span>{{ $t(value.active ? 'yes' : 'no') }}</span>
        </div>
      </div>

      <div class="col-12 col-sm-6 col-md-3">
        <action-button
          :actions="actions"
          :text="$t('actions')"
        />
      </div>
    </div>
  </div>
</template>
<script>
import ActionButton from '@/components/basics/buttons/ActionButton';
import IdentityPermission from '@/constants/IdentityPermission';

export default {
  name: 'AdSearchResult',
  components: {
    ActionButton,
  },
  props: {
    value: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      actions: [
        {
          label: this.$t('edit'),
          iconClass: 'far fa-edit',
          iconColor: '#105AEE',
          action: () => {
            this.$emit('on-edit');
          },
        },
        {
          label: this.$t('delete'),
          iconClass: 'far fa-trash-alt',
          iconColor: '#FF0000',
          action: () => this.$emit('on-delete'),
        },
      ],
    };
  },
  computed: {
    isMultiOffice() {
      return this.userHasPermissions(IdentityPermission.multi.office);
    },
  },
};
</script>
<style lang="scss" scoped>
.listview .cell {
  clear: left;
}
.listview .cell label {
  float: left;
  font-weight: bold;
}
.listview .cell span,
::v-deep .formatted-text label {
  margin-left: 5px;
  color: $color-primary;
  font-weight: bold;
}
.card {
  word-break: break-word;
}
</style>
