<template>
  <validation-observer ref="loginAdFormRef" novalidate>
    <div class="row">
      <div class="col-sm-12">
        <validation-provider v-slot="{ errors }" rules="required|max:128">
          <base-input
              v-model="model.description"
              :error="errors[0]"
              :error-msg="$t('invalidField')"
              :label="$t('description')"
              :max-length="128"
            />
        </validation-provider>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <custom-image-input
          v-model="model.media"
          accept=".jpg,.jpeg,.png"
          :max-size="10"
          :max-width="1248"
          :max-height="1260"
          :auto-delete="false"
          :file="model.mediaFile"
          :label="$t('adFile')"
          @clearFile="onDeleteLogo()"
        />
      </div>
    </div>
    <div class="row">
      <validation-provider
        v-slot="{ errors }"
        class="col-sm-12 col-md-4"
        :rules="`required`"
      >
        <filter-numeric
          v-model="model.time"
          :error="errors[0]"
          :error-msg="$t('mustEnterAttendees')"
          icon="fas fa-clock mr-2"
          :max="300"
          :min="5"
          title="duration"
        />
      </validation-provider>
      <validation-provider
        v-slot="{ errors }"
        class="col-sm-12 col-md-4"
        :rules="`required`"
      >
        <filter-numeric
          v-model="model.order"
          :error="errors[0]"
          :error-msg="$t('mustEnterAttendees')"
          icon="fas fa-copy mr-2"
          :max="15"
          :min="1"
          title="order"
        />
      </validation-provider>
      <validation-provider
        v-slot="{ errors }"
        class="col-sm-12 col-md-4"
        :rules="`required`"
      >
        <filter-numeric
          v-model="model.videoVolume"
          :error="errors[0]"
          :error-msg="$t('mustEnterAttendees')"
          icon="fas fa-volume mr-2"
          :max="100"
          :min="1"
          title="videoVolume"
        />
      </validation-provider>
    </div>
    <div class="row mt-2">
      <div class="col-sm-12 col-md-6">
        <b-form-checkbox
          v-model="model.active"
          class="mt-2 chk-available"
          switch
        >
          {{ $t('active') }}
        </b-form-checkbox>
      </div>
      <div class="col-sm-12 col-md-6">
        <button
          class="btn btn-primary float-right"
          variant="primary"
          @click="onSubmit()"
        >
          <i class="far fa-save" /> {{ $t('save') }}
        </button>
      </div>
    </div>
  </validation-observer>
</template>

<script>
import CustomImageInput from '@/components/CustomImageInput';

import { 
  createLoginAd as _createLoginAd,
  updateLoginAd as _updateLoginAd,
  deleteLoginAdMediaFile as _deleteLoginAdMediaFile
} 
from '@/services/AdService';
import BaseInput from '@/components/BaseInput.vue';

export default {
  name: 'LoginAdForm',
  components: { 
    CustomImageInput,
    BaseInput
  },
  props: 
  {
    value: {
      type: Object,
      required: false
    },
  },
  data() {
    return {
      model: this.value ? this.value : {
        id: null,
        key: null,
        description: null,
        time: 15,
        order: 1,
        videoVolume: 50,
        active: true,
        alternativeKey: null,
        blobPath: null,
        media: null,
        mediaFile: null,
      },
    };
  },
  mounted() {
  },
  methods: {
    async onSubmit() {
      const isValid = await this.$refs.loginAdFormRef.validate();

      if (!isValid) {
        return;
      }

      const payload = { ...this.model };

      payload.mediaFile = this.model.media;

      if(!payload.id) {
        _createLoginAd(payload)
          .then(() => {
            this.ShowSuccessSaveToast();

            this.$emit('close');
            this.$emit('submitted');
          })
          .catch((error) => this.ShowErrorToast(error.response.data.message));
      } else {
        _updateLoginAd(payload)
          .then(() => {
            this.ShowSuccessSaveToast();

            this.$emit('close');
            this.$emit('submitted');
          })
          .catch((error) => this.ShowErrorToast(error.response.data.message));
      }
    },
    async onDeleteLogo() {
      if(this.model.blobPath)
      {
        await _deleteLoginAdMediaFile(this.model.id);

        this.ShowSuccessSaveToast();
      }

      this.model.media = null;
      this.model.mediaFile = null;
      this.model.blobPath = null;
    },
  },
};
</script>
<style lang="scss" scoped>
</style>
