<template>
  <div class="card pt-2 border-light sponsorad-result">
    <div class="card-body">
      <div class="row">
        <div class="col col-sm-12 col-md-3 col-lg-4">
          <p>
            <label>{{ $t('description') }}: </label>
            <span>{{ value.name }}</span>
          </p>
          <p>
            <label>{{ $t('type') }}: </label>
            <span>{{ value.keyword }}</span>
          </p>
          <p>
            <label>{{ $t('imageUrl') }}: </label>
            <span>{{ value.blobPath || value.imageUrl }}</span>
          </p>
          <p>
            <label>{{ $t('time') }}: </label>
            <span>{{ value.adsTime }}</span>
          </p>
          <p>
            <label>{{ $t('group') }}: </label>
            <span>{{ value.adGroupId }}</span>
          </p>
        </div>

        <div class="col col-sm-12 col-md-3 col-lg-4">
          <p>
            <label>{{ $t('order') }}: </label>
            <span>{{ value.adsOrder }}</span>
          </p>
          <p>
            <label>{{ $t('monitor') }}: </label>
            <span>{{ value.monitor }}</span>
          </p>
          <p>
            <label>{{ $t('videoVolume') }}: </label>
            <span>{{ value.videoVolume }}</span>
          </p>
          <p>
            <label>{{ $t('beginning') }}: </label>
            <span>{{ beginDate }}</span>
          </p>
          <p>
            <label>{{ $t('expiration') }}: </label>
            <span>{{ expirationDate }}</span>
          </p>
        </div>

        <div class="col col-sm-12 col-md-3 col-lg-2">
          <p>
            <b-form-checkbox
              v-model="value.active"
              class="chk-available"
              :disabled="true"
              switch
            >
              {{ $t('available') }}
            </b-form-checkbox>
          </p>
        </div>
        <div class="col-12 col-sm-12 col-md-3 col-lg-2 pt-1">
          <action-button
            :actions="actions"
            class="float-right"
            :text="$t('actions')"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ActionButton from '@/components/basics/buttons/ActionButton';

export default {
  name: 'SponsorAdSearchResult',
  components: {
    ActionButton,
  },
  props: {
    value: {
      type: Object,
      default: () => {},
    },
    displayOffice: {
      type: Boolean,
      default: () => false,
    },
  },
  computed: {
    actions() {
      return [
        {
          label: this.$t('edit'),
          iconClass: 'far fa-edit',
          iconColor: '#105AEE',
          action: () => this.$emit('on-edit', this.value),
        },
        {
          label: this.$t('assingLocations'),
          iconClass: 'far fa-plus-square',
          iconColor: '#24852E',
          action: () => {
            this.$router.push({name: 'SponsorsAdsAssingLocation', params: {id: this.value.id}})
          },
        },
        {
          label: this.$t('download'),
          iconClass: 'far fa-download',
          iconColor: '#198E0F',
          visible: this.value.blobPath || this.value.imageUrl,
          action: () => this.$emit('on-download', this.value),
        },
        {
          label: this.$t('delete'),
          iconClass: 'far fa-trash-alt',
          iconColor: '#FF0000',
          action: () => this.$emit('on-delete'),
        },
      ];
    },
    beginDate() {
      if (!this.value.adBeginDate) return '-';

      return this.$moment(this.value.adBeginDate).format(
        'MM/DD/yyyy hh:mm:ss A',
      );
    },
    expirationDate() {
      if (!this.value.adEndDate) return '-';

      return this.$moment(this.value.adEndDate).format('MM/DD/yyyy hh:mm:ss A');
    },
  },
};
</script>

<style lang="scss" scoped>
.sponsorad-result p {
  margin-bottom: 0.2rem;
}

.sponsorad-result p i {
  cursor: pointer;
  font-size: 18px;
}

.sponsorad-result label {
  font-size: 14px;
  min-width: 100px;
  font-weight: bold;
}

.sponsorad-result p i,
.sponsorad-result span {
  color: $color-primary;
  font-weight: bold;
  margin-left: 5px;
}
</style>
