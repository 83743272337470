<template>
  <div class="p-4 listview card border-light">
    <div class="row">
      <div class="col-12 col-sm-6 col-md-4">
        <div v-if="!isMultiOffice" class="cell">
          <label>{{ $t('company') }}: </label>
          <span>{{ value.companyName || '-' }}</span>
        </div>
        <div v-if="!isMultiOffice" class="cell">
          <label>{{ $t('office') }}: </label>
          <span>{{ value.office || '-' }}</span>
        </div>
        <div class="cell">
          <label>{{ $t('town') }}: </label>
          <span>{{ value.city || '-' }}</span>
        </div>
      </div>
      <div class="col-12 col-sm-6 col-md-4">
        <div class="cell">
          <label>{{ $t('secondsAvailable') }}: </label>
          <span>{{ value.availableAdsPayingSec || '-' }}</span>
        </div>
        <div class="cell">
          <label>{{ $t('availableSpaces') }}: </label>
          <span>{{ value.availableSpaces || '-' }}</span>
        </div>
      </div>
      <div class="col-12 col-sm-6 col-md-4">
        <div class="cell">
          <label>{{ $t('secondsAvailablePaying') }}: </label>
          <span>{{ value.availableAdsPayingSec || '-' }}</span>
        </div>
        <div class="cell">
          <label>{{ $t('availableSpacesPaying') }}: </label>
          <span>{{ value.availablePayingSpaces || '-' }}</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import IdentityPermission from '@/constants/IdentityPermission';

export default {
  name: 'AdSearchResult',
  components: {},
  props: {
    value: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {};
  },
  computed: {
    isMultiOffice() {
      return this.userHasPermissions(IdentityPermission.multi.office);
    },
  },
};
</script>
<style lang="scss" scoped>
.listview .cell {
  clear: left;
}
.listview .cell label {
  float: left;
  font-weight: bold;
}
.listview .cell span,
::v-deep .formatted-text label {
  margin-left: 5px;
  color: $color-primary;
  font-weight: bold;
}
.card {
  word-break: break-word;
}
</style>
