<template>
  <div class="card pt-2 border-light sponsorad-result">
    <div class="card-body">
      <div class="row">
        <div class="col col-sm-12 col-md-3 col-lg-4">
          <p>
            <label>{{ $t('type') }}: </label>
            <span>{{ value.keyword }}</span>
          </p>
          <p>
            <label>{{ $t('imageUrl') }}: </label>
            <span>{{ value.blobPath || value.imageUrl }}</span>
          </p>
          <p>
            <label>{{ $t('time') }}: </label>
            <span>{{ value.time }}</span>
          </p>
        </div>

        <div class="col col-sm-12 col-md-3 col-lg-4">
          <p>
            <label>{{ $t('order') }}: </label>
            <span>{{ value.order }}</span>
          </p>
          <p>
            <label>{{ $t('videoVolume') }}: </label>
            <span>{{ value.videoVolume }}</span>
          </p>
        </div>

        <div class="col col-sm-12 col-md-3 col-lg-2">
          <p>
            <b-form-checkbox
              v-model="value.active"
              class="chk-available"
              :disabled="true"
              switch
            >
              {{ $t('available') }}
            </b-form-checkbox>
          </p>
        </div>
        <div class="col-12 col-sm-12 col-md-3 col-lg-2 pt-1">
          <action-button
            :actions="actions"
            class="float-right"
            :text="$t('actions')"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ActionButton from '@/components/basics/buttons/ActionButton';

export default {
  name: 'SponsorAdSearchResult',
  components: {
    ActionButton,
  },
  props: {
    value: {
      type: Object,
      default: () => {},
    },
    displayOffice: {
      type: Boolean,
      default: () => false,
    },
  },
  computed: {
    beginDate() {
      if(!this.value.adBeginDate)
        return '-'
      
      return this.$moment(this.value.adBeginDate)
        .format('MM/DD/yyyy hh:mm:ss A');
    },
    expirationDate() {
      if(!this.value.adEndDate)
        return '-'
      
      return this.$moment(this.value.adEndDate)
        .format('MM/DD/yyyy hh:mm:ss A');
    },
  },
  data() {
    return {
      actions: [
        {
          label: this.$t('edit'),
          iconClass: 'far fa-edit',
          iconColor: '#105AEE',
          action: () => this.$emit('on-edit', this.value),
        },
        {
          label: this.$t('view'),
          iconClass: 'far fa-eye',
          iconColor: '#198E0F',
          action: () => this.$emit('on-view', this.value),
        },
        {
          label: this.$t('delete'),
          iconClass: 'far fa-trash-alt',
          iconColor: '#FF0000',
          action: () => this.$emit('on-delete'),
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.sponsorad-result p {
  margin-bottom: 0.2rem;
}

.sponsorad-result p i {
  cursor: pointer;
  font-size: 18px;
}

.sponsorad-result label {
  font-size: 14px;
  min-width: 100px;
  font-weight: bold;
}

.sponsorad-result p i,
.sponsorad-result span {
  color: $color-primary;
  font-weight: bold;
  margin-left: 5px;
}
</style>
