<template>
  <b-card border-variant="light" class="py-0 listview" :class="color">
    <div class="fa icon" :class="['fa-' + icon, color]"></div>
    <b-row>
      <b-col class="col-12 text-center">
        <div class="cell title-container">
          <span class="title">{{ $t(title) }}</span>
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col class="col-12 text-center">
        <div class="cell">
          <span class="amount" :class="color">{{ amount }}</span>
        </div>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
export default {
  components: {},
  props: {
    amount: {
      type: Number,
      default: () => 0,
    },
    color: {
      type: String,
      default: () => '',
    },
    icon: {
      type: String,
      default: () => '',
    },
    title: {
      type: String,
      default: () => '',
    },
  },
  setup() {},
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.listview .cell {
  clear: left;
}

.listview .cell label {
  float: left;
  font-weight: bold;
}

.listview .cell span,
::v-deep .formatted-text label {
  margin-left: 5px;
  color: $color-primary;
  font-weight: bold;
}

.listview.grey {
  border: 1px solid #d0cccc !important;
  background-color: #f8f8f8;
  ::v-deep .c-actionButton {
    border: 1px solid #d0cccc !important;
  }
}

.listview.lightgreen {
  border: 1px solid #a4afa5 !important;
  background-color: #f2fff2;
  ::v-deep .c-actionButton {
    border: 1px solid #a4afa5 !important;
  }
}

.listview.green {
  border: 1px solid #2c8d14 !important;
  background-color: #efffeb;
  ::v-deep .c-actionButton {
    border: 1px solid #2c8d14 !important;
  }
}

.listview.red {
  border: 1px solid #fad4d4 !important;
  background-color: #fdf5f5;
  ::v-deep .c-actionButton {
    border: 1px solid #fad4d4 !important;
  }
}

.listview.orange {
  border: 1px solid #ff8105 !important;
  background-color: #fff7ef;
  ::v-deep .c-actionButton {
    border: 1px solid #ff8105 !important;
  }
}

.listview.blue {
  border: 1px solid #0f95db !important;
  background-color: #effaff;
  ::v-deep .c-actionButton {
    border: 1px solid #0f95db !important;
  }
}

.icon {
  position: absolute;
  font-size: 28px;
  font-weight: 100;
}

.icon.blue,
.amount.blue {
  color: #0f95db !important;
}

.icon.orange,
.amount.orange {
  color: #ff8105 !important;
}

.icon.green,
.amount.green {
  color: #2c8d14 !important;
}

.title {
  font-size: 20px !important;
  color: #000 !important;
}

.amount {
  font-size: 42px !important;
}

.title-container {
  width: 222px;
  min-height: 60px !important;
  margin: 0 auto;
}

.card {
  min-height: 136px;
}
</style>
